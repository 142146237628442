<template>
    <div>
        <b-row>
            <b-col md="3">
                <b-card>
                    <!--      Image and Name              -->
                    <div class="d-flex flex-column align-items-center ">
                        <b-avatar :src="tempUser.full_path" size="150" class="mb-2"/>
                        <h4 class="text-center">{{ tempUser.first_name }}<br/>{{ tempUser.last_name }}</h4>
                    </div>
                    <hr>
                    <!--      TC and Phone        -->
                    <div class="general-info text-center">
                        <div class="mb-1">
                            <div class="d-flex align-items-center justify-content-center">
                                <feather-icon class="mr-1 font-medium-4" icon="CreditCardIcon"></feather-icon>
                                <p class="mb-0 font-medium-3 font-weight-bold">TC</p>
                            </div>
                            <p>{{ tempUser.tc || "Null" }}</p>
                        </div>
                        <div class="mb-1">
                            <div class="d-flex align-items-center justify-content-center">
                                <feather-icon class="mr-1 font-medium-4" icon="PhoneIcon"></feather-icon>
                                <p class="mb-0 font-medium-3 font-weight-bold">{{$t('Phone')}}</p>
                            </div>
                            <a target="_blank" :href="'tel:'+tempUser.phone">{{ tempUser.phone || "Null" }}</a>
<!--                            <p>{{ tempUser.phone || "Null" }}</p>-->
                        </div>
                        <div class="mb-1">
                            <div class="d-flex align-items-center justify-content-center">
                                <feather-icon class="mr-1 font-medium-4" icon="MailIcon"></feather-icon>
                                <p class="mb-0 font-medium-3 font-weight-bold">{{$t('Email')}}</p>
                            </div>
                            <a target="_blank" :href="'mailto:'+tempUser.email">{{ tempUser.email || "Null" }}</a>
<!--                            <p>{{ tempUser.email || "Null" }}</p>-->
                        </div>
                    </div>
<!--                    <p class="text-center">{{ tempUser }}</p>-->
                </b-card>
            </b-col>
            <b-col md="9">
                <!-- For Numbers Card -->
                <div class="mb-2">
                    <FourNumbersCad :data="$store.state.user.userComplaintsInfo.fourNumbersData"></FourNumbersCad>
                </div>
                <!--   User's Complaint   -->
                <div>
                    <div>
                        <b-card no-body>
                            <b-card-body>
                                <div class="d-flex justify-content-end  flex-wrap">

                                    <!-- filter -->
                                    <b-form-group

                                        label-cols-sm="2"
                                        label-align-sm="left"
                                        label-size="sm"
                                        label-for="filterInput"
                                        class="mb-0"
                                    >
                                        <b-input-group size="sm">
                                            <b-form-input
                                                id="filterInput"
                                                v-model="pagination.filter"
                                                type="search"
                                                :placeholder="$t('Type to Search')"
                                            />
                                            <b-input-group-append>
                                                <b-button
                                                    :disabled="!pagination.filter"
                                                    @click="pagination.filter = ''"
                                                >
                                                    {{ $t("Clear") }}
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </div>
                            </b-card-body>

                            <b-table
                                striped
                                hover
                                responsive
                                class="position-relative"
                                :per-page="pagination.perPage"
                                :current-page="pagination.currentPage"
                                :items="complaints"
                                :fields="fields"
                                :filter="pagination.filter"
                                @row-clicked="navigateToComplaint"
                            >
                                <template #cell(address)="data">
                                    <b-button
                                        variant="info"
                                        :href="'https://www.google.com/maps/search/?api=1&query='+data.item.lat+','+data.item.long"
                                        target="_blank"
                                    >
                                        <div class="d-flex align-items-center text-body">
                                            <feather-icon
                                                icon="MapPinIcon"
                                                class="mr-25  text-white"
                                            />
                                            <span class="font-weight-bold  text-white">{{ $t('Location') }}</span>
                                        </div>
                                    </b-button>
                                </template>
                                <template #cell(userName)="data">
                                    <p>{{ data.item.user.first_name }} {{ data.item.user.last_name }}</p>
                                </template>
                                <template #cell(status)="data">
                                    <b-badge
                                        pill
                                        class="mr-75"
                                        :variant="statusColor(data.item.status)"
                                    >
                                        {{ $t(data.item.status) }}
                                    </b-badge>
                                </template>
                            </b-table>

                            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

                                <!-- page length -->
                                <b-form-group
                                    :label="$t('Per Page')"
                                    label-cols="8"
                                    label-align="left"
                                    label-size="md"
                                    label-for="perPageSelect"
                                    class="text-nowrap mb-md-0 mr-1"
                                >
                                    <b-form-select
                                        @change="updatePerPage"
                                        id="perPageSelect"
                                        v-model="pagination.perPage"
                                        size="sm"
                                        inline
                                        :options="pagination.pageOptions"
                                    />
                                </b-form-group>

                                <!-- pagination -->
                                <div>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :total-rows="totalRows"
                                        :per-page="pagination.perPage"
                                        first-number
                                        last-number
                                        prev-class="prev-item"
                                        next-class="next-item"
                                        class="mb-0"
                                        @change="updateCurrentPage"
                                    >
                                        <template #prev-text>
                                            <feather-icon
                                                icon="ChevronLeftIcon"
                                                size="18"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon
                                                icon="ChevronRightIcon"
                                                size="18"
                                            />
                                        </template>
                                    </b-pagination>
                                </div>
                            </b-card-body>
                        </b-card>
                    </div>
                </div>
            </b-col>
        </b-row>

    </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import FourNumbersCad from "@/views/pages/public/statistics/components/FourNumbersCad";
import {
    BRow,
    BCol,
    BCard, BButton,
    BFormInput,
    BCardText,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    BCardBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BPagination, BTable, BFormSelect,
} from 'bootstrap-vue';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import pagination from "@/views/components/pagination/Pagination";

export default {
    name: "userComplaint",
    components: {
        FourNumbersCad,
        ToastificationContent,
        BRow, BCol, BCard,
        BButton, BTable,
        BFormSelect,
        BFormInput,
        BCardText,
        BCardBody,
        BCardTitle,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BLink,
        BBadge,
        BFormGroup,
        BInputGroup,
        BInputGroupAppend,
        BImg,
        BPagination,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            swiperOptions: {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
            pagination: {
                perPage: 6,
                pageOptions: [6, 12, 18],
                currentPage: 1,
                filter: null,
                filterOn: [],
            },
            search_query: '',
            currentPage: 1,
            perPage: 50,
            rows: 140,
            complaintsStatus: [
                {title: 'submited', icon: 'FilePlusIcon'},
                {title: 'in_process', icon: 'ClockIcon'},
                {title: 'done', icon: 'CheckSquareIcon'},
                {title: 'canceled', icon: 'XCircleIcon'},
            ],
            fields: [
                // {key: 'avatar', label: this.$t('Image'),},
                {key: 'title', label: this.$t('title'), sortable: false},
                {key: 'short_description', label: this.$t('Description'), sortable: false},
                {key: 'status', label: this.$t('status'), sortable: false},
                {key: 'address', label: this.$t('address'), sortable: false},
                // {key: 'userName', label: this.$t('User name'), sortable: false},
                {key: 'created_at', label: this.$t('Created At'), sortable: false},
            ],
        }
    },
    watch: {
        currentPage: function (val) {
            this.getComplaints(val);
        },
        'pagination.currentPage': function (val) {
            // this.satisfactionOptions.current_page = val;
            // this.getSatisfactionList();
        }
    },
    computed: {
        complaints() {
            return this.$store.state.user.userComplaintsInfo.complaints;
        },
        tempUser() {
            return this.$store.state.user.tempUserData;
        },
        totalRows() {
            return this.$store.state.user.userComplaintsInfo.complaints.length;
        },
    },
    methods: {
        navigateToComplaint(row) {
            this.$router.push({name: 'sikayet-detaylari', params: {id: row.id}});
        },
        updateCurrentPage(currentPage) {
            this.pagination.currentPage = currentPage;
            // this.getSatisfactionList();
        },
        statusColor(status) {
            if (status === 'in_process') return 'light-info'
            if (status === 'canceled') return 'light-danger'
            if (status === 'submited') return 'light-primary'
            if (status === 'done') return 'light-success'
            return 'light-primary'
        },
        getUserComplaints() {
            this.$store.dispatch('user/getUserComplaints', {
                userId: this.$route.params.id,
                page: pagination.currentPage,
                productInPage: this.pagination.perPage,
                keyword: this.search_query,
            })
                .then(res => {
                })
                .catch(err => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: this.$t('Something went wrong'),
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                });
        }
    },
    created() {
        this.getUserComplaints();
    },
}
</script>

<style lang="scss">
.card-img-top {
    height: 15em;
    object-fit: contain;
    object-position: center;
    border-radius: 12px;
    overflow: hidden;
}

.general-info {
    p {
        margin-bottom: 0.5em;
        word-break: break-all;
    }
}
</style>
